import axios from "axios";
import router from "../../router";

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    idActionsRol: [],
    modules: [],
    deniedCompany: {
      title: "Error!",
      html: "La empresa no tiene permiso a esa sección",
      timer: 15000,
      icon: "error",
      confirmButtonColor: "#d60400",
    },
    deniedUser: {
      title: "Error!",
      html: "Usted no tiene permiso a esa sección",
      timer: 15000,
      icon: "error",
      confirmButtonColor: "#d60400",
    },
  },
  mutations: {
    setLogedIn(state, payload) {
      state.loggedIn = payload;
    },

    setActionsRol(state) {
      state.idActionsRol = [];
      if (localStorage.getItem("user") != "") {
        let user = JSON.parse(localStorage.getItem("user"));
        state.idActionsRol.push("HOME");
        for (let permission of user.permission) {
          let action = state.idActionsRol.find((e) => e === permission);
          if (typeof action == "undefined") state.idActionsRol.push(permission);
        }
      }
    },

    setAvailableModules(state) {
      if (localStorage.getItem("user") != "") {
        let user = JSON.parse(localStorage.getItem("user"));
        state.modules = user.modules;
      }
    },
  },
  actions: {
    errorRequest({ commit, state, dispatch, rootState }, payload) {
      console.log("entró en el errorRequest");
      rootState.master.alertas = "";
      let errorValidacion = false;

      errorValidacion = payload.data.status === 422;

      if (errorValidacion) {
        // ERROR DE VALIDACION DEL REQUEST

        let messages = payload.data.response.data.validation_messages;

        //console.log(messages);
        Object.keys(payload.data.response.data.validation_messages).forEach(
          (item) => {
            let msg = messages[item];
            //console.log(typeof msg);

            if (typeof msg == "object") {
              Object.keys(msg).forEach((item2) => {
                rootState.master.alertas += msg[item2] + "<br />";
              });
            } else {
              for (let i = 0; i < msg.length; i++) {
                rootState.master.alertas += msg[i] + "<br />";
              }
            }
          }
        );
      } else if (payload.data.status === 500) {
        // MAYORMENTE ERROR DE PROGRAMACIÓN
        if (typeof payload.data.datos != "undefined") {
          let i = 1;
          Object.keys(payload.data.datos).forEach((item) => {
            let msg = payload.data.datos[item];
            if (i < 5) rootState.master.alertas += item + " " + msg + "<br />";
            i++;
          });
        } else {
          //console.log(payload);
          rootState.master.alertas += payload.data.response.data.detail;
        }
      } else {
        rootState.master.alertas =
          "Ha ocurrido un error inesperado, intente nuevamente";
      }

      /* if(typeof payload.data.btn == "undefined" && state.loadingBtn)
                commit('master/setLoadingBtn',false, { root: true })
            else if(payload.data.btn===2)
                commit('master/setLoadingBtn2',false, { root: true }) */

      let error500 = !(
        typeof payload.data != "undefined" && payload.data.status === 500
      );

      let data = {
        param: {
          title: "Error!",
          html: rootState.master.alertas,
          icon: "error",
          toast: error500,
          timer: !error500 ? 25000 : rootState.master.sweetAlert.timer,
          position: rootState.master.sweetAlert.position,
          confirmButtonColor: "#d60400",
        },
      };
      dispatch("master/alertNotification", data, { root: true });
    },

    login({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        commit("master/setLoadingBtn", true, { root: true });
        commit("master/setUrl", "oauth", { root: true });
        localStorage.setItem("tenantId", payload.company);

        axios
          .post(rootState.master.url, {
            client_id: payload.user,
            client_secret: payload.password,
            grant_type: "client_credentials",
          })
          .then((res) => {
            resolve(res);

            if (
              res.statusText === "OK" &&
              res.status === 200 &&
              res.data.access_token
            ) {
              let token = res.data.access_token;
              localStorage.setItem("token", token);

              commit("master/setUrl", "user-laboratorio", { root: true });

              axios
                .post(rootState.master.url, {
                  user: payload.user,
                  client_id: payload.user,
                  system: "gestion_grupo_innofarm",
                  dataBase: payload.company,
                  comment:
                    "Ha iniciado sesión en el sistema de gestión general de innofarm",
                  tenantId: payload.company,
                })
                .then((res) => {
                  res.data.user.pass = payload.password;
                  let userString = JSON.stringify(res.data.user);
                  localStorage.setItem("user", userString);
                  dispatch("checkSessionStatus");
                  router.push("/");

                  dispatch(
                    "master/alertNotification",
                    {
                      param: {
                        html: "Bienvenido " + payload.user,
                      },
                    },
                    { root: true }
                  );
                });
            }
          })
          .catch((err) => {
            reject(err);

            if (!state.loadingBtn)
              commit("master/setLoadingBtn", null, { root: true });

            let response = err.response;

            if (!response.data.login) {
              dispatch(
                "master/alertNotification",
                {
                  param: {
                    title: "Error",
                    icon: "error",
                    html: "El usuario o contraseña son incorrectos",
                    confirmButtonColor: "#d60400",
                  },
                },
                { root: true }
              );
            } else {
              dispatch(
                "errorRequest",
                {
                  data: {
                    datos: response.data.errors,
                    status: response.status,
                  },
                },
                { root: true }
              );
            }
          });
      });
    },

    checkSessionStatus({ commit, dispatch }) {
      let existToken =
        localStorage.getItem("token") != null &&
        localStorage.getItem("token") !== undefined &&
        typeof localStorage.getItem("token") !== "undefined";

      if (existToken) {
        commit("master/setToken", localStorage.getItem("token"), {
          root: true,
        });
        commit("master/setTenantId", localStorage.getItem("tenantId"), {
          root: true,
        });
        if (localStorage.getItem("user") != null) {
          commit("master/setUser", JSON.parse(localStorage.getItem("user")), {
            root: true,
          });
          commit("setActionsRol");
          commit("setAvailableModules");
          commit("setLogedIn", true);
        }
      }
    },

    getModules({ state, commit, dispatch, rootState }) {
      commit("master/setUrl", `user-laboratorio/${rootState.master.tenantId}`, {
        root: true,
      });
      dispatch(
        "master/requestApi",
        {
          method: "PUT",
          data: {},
        },
        { root: true }
      ).then((res) => {
        let user = JSON.parse(localStorage.getItem("user"));
        user.modules = res.data.modules;
        user.permission = res.data.permission;
        localStorage.setItem("user", JSON.stringify(user));
        commit("setActionsRol");
        commit("setAvailableModules");
      });
    },
  },
  getters: {
    // GETTERS ACCESS IS LOGGED IN
    getIsLoggedIn(state) {
      return state.loggedIn;
    },

    // GETTERS ACCESS COMPANY APPLICATION
    pos(state) {
      return state.modules.find((e) => e.module == "POS");
    },

    contabilidad(state) {
      return state.modules.find((e) => e.module == "CONTABILIDAD");
    },

    store(state) {
      return state.modules.find((e) => e.module == "BODEGA");
    },

    purchases(state) {
      return state.modules.find((e) => e.module == "COMPRAS");
    },

    admin(state) {
      return state.modules.find((e) => e.module == "ADMIN");
    },

    adminWeb(state) {
      return state.modules.find((e) => e.module == "ADMIN_WEB");
    },

    adminCrm(state) {
      return state.modules.find((e) => e.module == "ADMIN_CRM");
    },

    // GETTERS ACCESS USER PERMISSIONS
    validateSupplier(state) {
      return state.idActionsRol.find((e) => e === "VALIDAR_PROVEEDOR");
    },

    adminGroups(state) {
      return state.idActionsRol.find((e) => e === "ADMIN_WORKGROUPS");
    },

    adminTask(state) {
      return state.idActionsRol.find((e) => e === "ASSIGN_REASSIGN_TASK");
    },

    adminUsers(state) {
      return state.idActionsRol.find((e) => e === "ADMIN_SYSTEM_USERS");
    },

    btnAbrirCaja(state) {
      return state.idActionsRol.find((e) => e === "ABRIR_CAJA");
    },

    conciliarCaja(state) {
      return state.idActionsRol.find((e) => e === "CONCILIAR_CAJA");
    },
    listaCajasContabilidad(state) {
      return state.idActionsRol.find((e) => e === "CONTA_CAJAS");
    },
    listaCuentasContables(state) {
      return state.idActionsRol.find((e) => e === "CONTA_PLAN_CUENTAS");
    },

    listaTransferencias(state) {
      return state.idActionsRol.find((e) => e === "CONTABILIDAD_ADMIN");
    },

    supplierQuotes(state) {
      return state.idActionsRol.find((e) => e === "SUPPLIER_QUOTES");
    },

    supplierAdvance(state) {
      return state.idActionsRol.find((e) => e === "SUPPLIER_ADVANCE");
    },

    aproveAdvance(state) {
      return state.idActionsRol.find((e) => e === "APROBAR_ANTICIPO");
    },

    manageSupplierPayment(state) {
      return state.idActionsRol.find((e) => e === "GEST_PAGOS_PROVEE");
    },

    ledgerWithBalancesReport(state) {
      return state.idActionsRol.find((e) => e === "REP_LIB_MAYOR_SALDO");
    },

    unifiedExpenseReport(state) {
      return state.idActionsRol.find((e) => e === "REP_UNIFICADO_GASTO");
    },

    suppliersList(state) {
      return state.idActionsRol.find((e) => e === "LISTA_PROVEEDORES");
    },

    electronicDocumentList(state) {
      return state.idActionsRol.find((e) => e === "LISTA_COMPROB_ELECTR");
    },

    cajas(state) {
      return state.idActionsRol.find((e) => e === "CONTA_CAJAS");
    },

    posAdminCuentasPorCobrar(state) {
      return state.idActionsRol.find((e) => e === "POS_ADMIN_CCOBRAR");
    },

    posAdminPrescriptor(state) {
      return state.idActionsRol.find((e) => e === "POS_ADMIN_PRESCRIPTOR");
    },

    listaInventarioPorCaducar(state) {
      return state.idActionsRol.find((e) => e === "LIST_INV_POR_CADUCAR");
    },

    listaProductos(state) {
      return state.idActionsRol.find((e) => e === "LISTA_PRODUCTOS");
    },

    listaCategoriasProdutos(state) {
      return state.idActionsRol.find((e) => e === "LISTA_CATEGORIA_PROD");
    },

    promotions(state) {
      return state.idActionsRol.find((e) => e === "PROMOCIONES_ADMIN");
    },

    agreement(state) {
      return state.idActionsRol.find((e) => e === "ACUERDOS_ADMIN");
    },

    priceRules(state) {
      return state.idActionsRol.find((e) => e === "PRICE_RULES_ADMIN");
    },

    preciosCuatrimestre(state) {
      return state.idActionsRol.find((e) => e === "PRECIOS_CUATRIMESTRE");
    },

    kardexFisicoProducto(state) {
      return state.idActionsRol.find((e) => e === "BOD_KARDEX");
    },

    pagosRecibidos(state) {
      return state.idActionsRol.find((e) => e === "PAGOS_RECIBIDOS");
      //return state.idActionsRol.find(e => e === 'BOD_KARDEX')
    },

    pagosDesembolsos(state) {
      return state.idActionsRol.find((e) => e === "PAGOS_DESEMBOLSOS");
      //return state.idActionsRol.find(e => e === 'BOD_KARDEX')
    },

    puedoAprobarOrdenPago(state) {
      return state.idActionsRol.find((e) => e === "APROBAR_ORDEN_PAGO");
    },

    puedoProcesarOrdenPago(state) {
      return state.idActionsRol.find((e) => e === "PROCESAR_ORDEN_PAGO");
    },
    puedoSolicitarOrdenPago(state) {
      return state.idActionsRol.find((e) => e === "SOLICITAR_ORDEN_PAGO");
    },
    puedoSolicitarReembolsoCliente(state) {
      return state.idActionsRol.find((e) => e === "SOLICITAR_REEMB_CLIENTE");
    },
    puedoSolicitarPagoServicioTerceros(state) {
      return state.idActionsRol.find((e) => e === "SOLICITAR_PAGO_SRVTERC");
    },
    puedoBajaCarteraCliente(state) {
      return state.idActionsRol.find((e) => e === "BAJA_CARTERA_CLIENTE");
    },

    puedoBajaCarteraProveedor(state) {
      return state.idActionsRol.find((e) => e === "BAJA_CARTERA_PROV");
    },

    puedoCruceCuentasClientes(state) {
      return state.idActionsRol.find((e) => e === "CRUCE_CUENTAS_CLIENTES");
    },

    puedoAnularPagoCliente(state) {
      return state.idActionsRol.find((e) => e === "ANULAR_PAGO_CLIENTE");
    },
    puedoAnularPagoProveedor(state) {
      return state.idActionsRol.find((e) => e === "ANULAR_PAGO_PROV");
    },
    puedoAnularRetencionEmitida(state) {
      return state.idActionsRol.find((e) => e === "ANULAR_RETENCION_EMITIDA");
    },

    libroBancos(state) {
      return state.idActionsRol.find((e) => e === "LIBRO_BANCOS");
    },
    puedoCrearCotizacionVentaPos(state) {
      return state.idActionsRol.find((e) => e === "CREAR_COTIZACION_POS");
    },
    puedoDescargarContenido(state) {
      return state.idActionsRol.find((e) => e === "CONTENTMGR_VIEW");
    },

    reporteVentasProducto(state) {
      return state.idActionsRol.find((e) => e === "REP_VENTAS_PRODUCTO");
    },

    reporteCloseup(state) {
      return state.idActionsRol.find((e) => e === "REP_CLOSEUP");
    },

    waDispositivos(state) {
      return state.idActionsRol.find((e) => e === "WA_DISPOSITIVOS");
    },

    waContactos(state) {
      return state.idActionsRol.find((e) => e === "WA_CONTACTOS");
    },

    waMensajesContacto(state) {
      return state.idActionsRol.find((e) => e === "WA_MENSAJES_CONTACTO");
    },

    plantillasMensajes(state) {
      return state.idActionsRol.find((e) => e === "PLANTILLAS_MENSAJES");
    },

    securityPermission(state) {
      return state.idActionsRol.find((e) => e === "SECURITY_PERMISSION");
    },

    puedoActivarAsiento(state) {
      return state.idActionsRol.find(
        (e) => e === "CONTABILIDAD_ACTIVAR_ASIENTO"
      );
    },

    waCampania(state) {
      return state.idActionsRol.find((e) => e === "WA_CAMPANIA");
    },

    
  },
};
